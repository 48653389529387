<template>
	<div class="mt-3">
		<b-form @submit.prevent="lmOrHawm">

            <div class="row mb-3">
                <div class="col-sm-5 m-auto">
					<div class="input-group mb-3">
						<b-form-input type="text" tabindex="1" autocomplete="off" ref="lm_or_hawm" @keydown.tab="lmOrHawm" autofocus v-model="lm_or_hawm" placeholder="Scan LM-AWB or HAWB"></b-form-input>
						<div class="input-scan">
							<svg tabindex="2" xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" class="bi bi-upc-scan" viewBox="0 0 16 16">
								<path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"></path>
							</svg>
						</div>
					</div>
                </div>
            </div>

			<div v-if="bagdetails.fm_awb" class="row">
				<div class="col-sm-12">
					<table class="table table-bordered">
						<tr class="bg-light">
							<th>LM AWB</th>
							<th>HAWB</th>
							<th>Dimension (cm)</th>
							<th>Country</th>
							<th>Custom Type</th>
							<th>Clearance Type</th>
							<th>Bag Code</th>
						</tr>
						<transition name="fade">
						<tr>
							<td>{{bagdetails.lm_awb}}</td>
							<td>{{bagdetails.h_awb}}</td>
							<td><span v-if="bagdetails.length || bagdetails.height">{{bagdetails.length}} x {{bagdetails.height}} x {{bagdetails.width}}</span></td>
							<td>{{bagdetails.country}}</td>
							<td>{{bagdetails.custom_type}}</td>
							<td>{{bagdetails.clearance_type}} {{bagdetails.carrier_type}}</td>
							<td>{{bagdetails.bag_code || '--'}}</td>
						</tr>
						</transition>
					</table>
				</div>
			</div>

			<div v-if="openBags.length || closedBags.length" class="row mt-4">
				<div class="col-sm-12">
					<h5>Bags</h5>
				</div>
				<div class="col-sm-12">
					<table class="table table-bordered">
						<thead>
							<tr class="bg-light">
								<th>Bag Code</th>
								<th>Bag Type</th>
								<th>No. of packets</th>
								<th>Total Weight</th>
								<th></th>
							</tr>
						</thead>

						<!-- Current open bag -->
						<tbody v-if="openBags.length">
							<tr v-for="bag, i in openBags" :key="`open_${i}`" :class="{full:bag && bag.weight>=30}">
								<td v-if="bag && bag.bag_code">{{bag.bag_code}} <b-spinner v-if="bagdetails.bag_code == bag.bag_code" small variant="success" type="grow"></b-spinner></td><td v-else></td>
								<td v-if="bag && bag.country">{{bag.country}} {{bag.custom_type}} {{bag.clearance_type}} {{bag.carrier_type}}</td><td v-else></td>
								<td v-if="bag && bag.qty">{{bag.qty}}</td><td v-else></td>
								<td v-if="bag && bag.weight">{{ parseFloat(bag.weight).toFixed(3) }}kg <span class="text-muted">/ 30kg</span></td><td v-else></td>
								<td>
									<span class="btn-sm btn-info pointer" @click="closeBag(i)" title="Close Bag" v-b-tooltip.hover>Close</span>
									<!-- <a :href="`http://bwipjs-api.metafloor.com/?bcid=code128&text=${bag.bag_code}&includetext`" class="btn btn-sm btn-success ml-2" title="Print QR Code" v-b-tooltip.hover><b-icon-printer></b-icon-printer></a> -->
								</td>
							</tr>
						</tbody>

						<!-- Closed bags -->
						<tbody v-if="closedBags.length > 0">
							<tr v-for="bag, i in closedBags" :key="`close_${i}`">
								<td v-if="bag && bag.bag_code">{{bag.bag_code}}</td><td v-else></td>
								<td v-if="bag && bag.country">{{bag.country}} {{bag.custom_type}} {{bag.clearance_type}} {{bag.carrier_type}}</td><td v-else></td>
								<td v-if="bag && bag.qty">{{bag.qty}}</td><td v-else></td>
								<td v-if="bag && bag.weight">{{ parseFloat(bag.weight).toFixed(3) }}kg <span class="text-muted">/ 30kg</span></td><td v-else></td>
								<td v-if="bag && bag.bag_code">
									<span v-if="!scannedBags.includes(bag.bag_code)" class="btn-sm btn-success pointer" @click="reOpenBag(i)" title="Re Open Bag" v-b-tooltip.hover>Re-Open</span>
									<span @click="printBarcode(bag)" class="btn-sm btn-primary pointer ml-2" title="Print Barcode" v-b-tooltip.hover><b-icon-printer></b-icon-printer></span>
									<span class="badge badge-danger ml-2">Closed</span>
								</td><td v-else></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

		</b-form>


		<!-- Print Bagcode -->
		<div style="display:none">
			<span id="capture">
				<div style="font:bold 15px monospace;margin-bottom:5px">Bag Type: {{print_data.custom_type}} {{print_data.clearance_type ? `- ${print_data.clearance_type}` : ''}}</div>
				<VueBarcode :value="print_data.bag_code" height="75" width="1">
					Failed to generate barcode.
				</VueBarcode>
			</span>
		</div>
	</div>
</template>


<script>

import VueBarcode from 'vue-barcode';

export default {
	text: 'Bagging',
	title: 'Bagging',
	components: {
		VueBarcode
	},
	data() {
		return {
			isBusy: false,
			lm_or_hawm: '',
			print_data: {},
			bagdetails: this.$store.getters.global.bagdetails || {},
			closedBags: this.$store.getters.global.closedBags || [],
			openBags: this.$store.getters.global.openBags || [],
		}
	},
	methods: {
		async lmOrHawm() {
			if(this.lm_or_hawm == 'close') {

				try {
					const bags = [...this.openBags.map(i => i.bag_code), ...this.closedBags.map(i => i.bag_code)]
					const res = await this.axios.post(`/closeBaggingAndSendMail`, {bags: bags})
					if(res.data.success) {
						this.bagdetails = {}
						this.openBags = []
						this.closedBags = []

						// Clear bags from store
						this.$store.commit('setGlobal', {'openBags': []})
						this.$store.commit('setGlobal', {'closedBags': []})
						this.$store.commit('setGlobal', {'bagdetails': {}})
						this.lm_or_hawm = ''
						this.$refs.lm_or_hawm.focus()
						this.popToast('Success', 'Bagging has been closed', 'success')
					} else {
						this.popToast('Failed!', res.data.message, 'danger')
					}
				} catch (err) {
					console.error(err)
				}
				return
			}

			try {
				let carrier_id = 0
				const carrierIdRes = await this.axios.post('/carrier-id', {lm_awb: this.lm_or_hawm})
				if(carrierIdRes.data && carrierIdRes.data.success)
				carrier_id = carrierIdRes.data.carrier_id;

				if(this.lm_or_hawm.length > 28 && carrier_id != 2 && carrier_id != 7 && carrier_id != 20) {
					this.lm_or_hawm = this.lm_or_hawm.substring(8)
				}
				else if(carrier_id == 20) {
					this.lm_or_hawm = this.lm_or_hawm.substring(8, 22)
				}

				const res = await this.axios.post(`/fetchdetails`, {lmLabel: this.lm_or_hawm})
				if(res.data.success) {
					this.bagdetails = res.data.data
					if(this.bagdetails.bag_code) {
						this.popToast('Alert!', `Bag ${this.bagdetails.bag_code} already Assigned`, 'info')
						this.$store.commit('setGlobal', {'bagdetails': this.bagdetails})
						this.$refs.lm_or_hawm.focus()
						this.lm_or_hawm = ''
						return false;
					}

					// Check if bag is open...
					let i = -1
					if(this.bagdetails.country == 'USA') {
						i = this.openBags.findIndex(i => i.country == this.bagdetails.country && i.custom_type == this.bagdetails.custom_type && i.clearance_type == this.bagdetails.clearance_type && i.carrier_type == this.bagdetails.carrier_type)
					} else if(this.bagdetails.country == 'AUS') {
						i = this.openBags.findIndex(i => i.country == this.bagdetails.country && i.custom_type == this.bagdetails.custom_type && i.carrier_type == this.bagdetails.carrier_type)
					} else {
						i = this.openBags.findIndex(i => i.country == this.bagdetails.country && i.custom_type == this.bagdetails.custom_type)
					}
					if(i >= 0) {
						this.bagdetails.bag_code = this.openBags[i].bag_code
						const res = await this.onSubmit()
						// const res = true
						if(res) {
							this.openBags[i].weight += parseFloat(this.bagdetails.weight)
							this.openBags[i].qty++
							this.bagdetails.bag_code = this.openBags[i].bag_code
						}
					} else {
						await this.createNewBag()
					}
					this.$store.commit('setGlobal', {'openBags': this.openBags})
					this.$store.commit('setGlobal', {'bagdetails': this.bagdetails})
				} else {
					this.popToast('Failed!', res.data.message, 'danger')
				}
				setTimeout(() => {
					this.$refs.lm_or_hawm.focus()
					this.lm_or_hawm = ''
				});
			} catch (err) {
				console.error(err)	
			}
		},
		async createNewBag() {
			const bagid = `${this.bagdetails.id}`.padStart(6, "0");
			const bag_code = `SY${this.bagdetails.country}${bagid}`.replace(' ', '')
			this.bagdetails.bag_code = bag_code
			const res = await this.onSubmit()
			// const res = true
			if(res) {
				const newbag = {
					country: this.bagdetails.country,
					custom_type: this.bagdetails.custom_type,
					clearance_type: this.bagdetails.clearance_type,
					weight: parseFloat(this.bagdetails.weight),
					qty: 1,
					bag_code: this.bagdetails.bag_code
				}
				if(this.bagdetails.carrier_type) {
					newbag.carrier_type = this.bagdetails.carrier_type
				}
				this.openBags.push(newbag)
			}
		},
		printBarcode(bag) {
			this.print_data = bag
			setTimeout(() => {
				var contents = document.getElementById('capture').innerHTML;
				var frame1 = document.createElement('iframe');
				frame1.name = "frame1";
				document.body.appendChild(frame1);
				var frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
				frameDoc.document.open();
				frameDoc.document.write(contents);
				frameDoc.document.close();
				setTimeout(function () {
					window.frames["frame1"].focus();
					window.frames["frame1"].print();
					document.body.removeChild(frame1);
				}, 500);
				return false;
			}, 500);
		},
		async closeBag(i) {
			if(confirm('Are you sure want to close this bag?')) {
				try {
					const bag_code = this.openBags[i].bag_code
					const res = await this.axios.post(`/closeIndividualBag`, {bag_code: bag_code});
					if(res.data.success) {
						const close = this.openBags.splice(i, 1)[0]
						this.closedBags.push(close)
						this.$store.commit('setGlobal', {'closedBags': this.closedBags})
						// this.createNewBag()
						this.printBarcode(close)
						this.popToast('Success', `Bag ${close.bag_code} has been closed.`, 'success')
					} else {
						this.popToast('Failed!', res.data.message, 'danger')
					}
				} catch (err) {
					console.error(err)
				}
				this.$refs.lm_or_hawm.focus()
			}
		},
		async reOpenBag(i) {
			if(confirm('Are you sure want to re-open this bag?')) {
				try {
					const bag_code = this.closedBags[i].bag_code
					const res = await this.axios.post(`/reopenBag`, {bag_code: bag_code});
					if(res.data.success) {
						const open = this.closedBags.splice(i, 1)[0]
						this.openBags.push(open)
						this.$store.commit('setGlobal', {'openBags': this.openBags})
						this.popToast('Success', `Bag ${open.bag_code} has been re-opened.`, 'success')
					} else {
						this.popToast('Failed!', res.data.message, 'danger')
					}
				} catch (err) {
					console.error(err)
				}
				this.$refs.lm_or_hawm.focus()
			}
		},
		async onSubmit() {
			try {
				this.isBusy = true
				const res = await this.axios.post('/bagging', {'lm_awb':this.bagdetails.lm_awb, 'bag_code': this.bagdetails.bag_code});
				if(res.data.success) {
					return true
					// this.popToast('Success!', res.data.message, 'success')
				} else {
					this.popToast('Alert!', res.data.message, 'info')
					return false;
				}
			} catch (err) {
				console.log(err)
			}
			this.isBusy = false
		},
		addItem() {
			this.form.lm_awb.push('')
		}
	},
	computed: {
		scannedBags() {
			return this.$store.getters.global.scannedBags || []
		}
	}
}
</script>

<style scoped>
	tbody tr:first-child input {
		margin-bottom: 8px;
	}
	.full {
		background: #ffe5e3;
	}
</style>